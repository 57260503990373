module.exports = [{
      plugin: require('/builds/beside-the-park/btp-labs/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-30377828-2","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/builds/beside-the-park/btp-labs/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TDN74Q7F"},
    },{
      plugin: require('/builds/beside-the-park/btp-labs/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
