/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import Cookies from 'js-cookie';

export const onClientEntry = () => {
  const consentGiven = Cookies.get('cookies') === 'true' ? 'granted' : 'denied';
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() { window.dataLayer.push(arguments); };
  window.gtag('consent', 'default', {
    ad_user_data: consentGiven,
    ad_personalization: consentGiven,
    ad_storage: consentGiven,
    analytics_storage: consentGiven,
  });
};
